<template>
  <section id="portfolio" class="dark-section">
    <div class="container-fluid">
      <h1 class="section-header">{{ heading }}</h1>
      <br>
      <div id="portfolio-container" class="row">
        <div v-for="filter in filters" :key="filter" class="col-12">
          <h2 class="section-header filter-title">{{ filter }}</h2>
          <div class="row">
            <div
              v-for="(item, index) in filteredProjectsByFilter(filter)"
              :key="index"
              class="col-sm-12 col-md-4 flex-col"
            >
              <div class="portfolio-item">
                <img :src="require(`../../assets/images/portfolio/${item.image}`)" rel="preload"/>
                <div class="overlay">
                  <a :href="item.link" title="Live Demo" target="_blank">
                    <div class="portfolio-item-content">
                      <h3>{{ item.title }}</h3>
                      <p>{{ item.description }}</p>
                      <div v-if="item.source !== '' " class="link-icons">
                        <a :href="item.source" title="Source Code" target="_blank">
                          <i class="fas fa-code fa-2x"></i>
                        </a>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Arrow />
  </section>
</template>

<script>
import data from "../../data/data.json";
import Arrow from "../components/Arrow.vue";

export default {
  name: "portfolio",
  props: {},
  components: {
    Arrow,
  },
  data() {
    return {
      projects: data.portfolio.projects,
      heading: data.main.headings.portfolio,
    };
  },
  computed: {
    filters() {
      let filterList = [];
      this.projects.forEach((project) => {
        if (!filterList.includes(project.filter)) {
          filterList.push(project.filter);
        }
      });
      return filterList;
    },
  },
  methods: {
    filteredProjectsByFilter(filter) {
      return this.projects.filter((project) => project.filter === filter);
    },
  },
};
</script>

<style lang="scss">
.filter-title {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: white; /* Set the title color to white */
  text-transform: capitalize; /* Ensure text is not transformed to uppercase */
}
</style>
