<template>
  <section id="skills" class="light-section">
    <div class="container-fluid">
      <h1 class="section-header">{{ heading }}</h1>
      <br>
      <div id="portfolio-container" class="d-flex flex-wrap">
        <div v-for="item in skills" :key="item.category" class="col-sm-12 col-md-4 flex-col">
          <div class="portfolio-item">
            <div class="portfolio-item-content overlay">
              <div class="category-heading">
                <h2><i :class="item.faClass"></i> {{ item.category }}</h2>
                <h3 v-if="item.subtitle !== ''">{{ item.subtitle }}</h3>
              </div>
              <div v-if="item.skillList.length !== 0" class="skillbars">
                <div class="skill" v-for="skill in item.skillList" :key="skill.name">
                  <h3 class="skill-name" v-html="skill.name"></h3>
                  <div class="outer-bar">
                    <div :class="'inner-bar level--' + skill.level"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Arrow />
  </section>
</template>

<script>
import data from "../../data/data.json";
import Arrow from "../components/Arrow.vue";

export default {
  name: "Skills",
  components: {
    Arrow,
  },
  data() {
    return {
      skills: data.skills.categories.map((item) => ({
        ...item,
      })),
      heading: data.main.headings.skills,
    };
  },
};
</script>

<style lang="scss">
#portfolio-container {
  display: flex;
  flex-wrap: wrap;
}

.category-heading {
  margin-bottom: 15px;
}

.skillbars .skill {
  margin-bottom: 10px;
}

.outer-bar {
  background: #eee;
  border-radius: 5px;
  overflow: hidden;
}

.inner-bar {
  height: 100%;
  border-radius: 5px;
}

.level--1 { width: 20%; }
.level--2 { width: 40%; }
.level--3 { width: 60%; }
.level--4 { width: 80%; }
.level--5 { width: 100%; }

/* Ensure flexbox layout for larger screens */
@media (min-width: 768px) {
  .flex-col {
    flex: 1 1 33.3333%;
    padding: 10px;
  }
}

/* Stack items on top of each other for mobile screens */
@media (max-width: 767px) {
  .flex-col {
    flex: 1 1 100%;
    padding: 10px;
  }
}
</style>
