var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"light-section",attrs:{"id":"contact"}},[_c('div',{staticClass:"container-fluid"},[_c('h1',{staticClass:"section-header"},[_vm._v(_vm._s(_vm.heading))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-6 flex-col",staticStyle:{"margin":"0 auto"},attrs:{"data-aos":"fade-up","data-aos-duration":"1000"}},[_c('div',{staticClass:"contact-content"},[_c('h2',[_vm._v("Connect with Me")]),_c('div',{staticClass:"contact-text"},[_vm._v(_vm._s(_vm.contact.text))]),_c('div',{staticClass:"contact-info"},[_c('ul',{staticClass:"list-group list-group-flush"},[(_vm.contact.city !=='')?_c('li',{staticClass:"list-group-item"},[_vm._m(0),_c('br',{staticClass:"d-md-none"}),_c('span',[_vm._v("  "+_vm._s(_vm.contact.city))])]):_vm._e(),(_vm.contact.email !=='')?_c('li',{staticClass:"list-group-item"},[_vm._m(1),_c('br',{staticClass:"d-md-none"}),_c('a',{attrs:{"href":'mailto:'+ _vm.contact.email}},[_c('span',[_vm._v(_vm._s(_vm.contact.email))])])]):_vm._e(),_c('li',{staticClass:"list-group-item"},[_vm._m(2),_c('br',{staticClass:"d-md-none"}),_vm._l((_vm.social),function(item,i){return _c('div',{key:i,staticClass:"social-item social-spacing list-inline-item"},[_c('a',{attrs:{"href":item.url,"alt":item.name}},[_c('i',{class:'fa-lg '+item.faClass})])])})],2),(_vm.contact.resumeLink !=='')?_c('li',{staticClass:"list-group-item"},[_c('a',{attrs:{"href":_vm.contact.resumeLink,"target":"_blank","alt":"resume"}},[_vm._m(3)])]):_vm._e()])])])]),(_vm.contact.form)?_c('div',{staticClass:"col-sm-12 col-md-6 flex-col",attrs:{"data-aos":"fade-up","data-aos-duration":"1000"}},[(_vm.contact.formTitle !=='')?_c('h2',[_vm._v(_vm._s(_vm.contact.formTitle))]):_vm._e(),_c('div',{staticClass:"container contact-form"},[_c('form',{attrs:{"action":_vm.contact.formEndPoint,"method":"POST","target":"_blank"}},[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7)])])]):_vm._e()])]),_vm._m(8)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"d-inline"},[_c('i',{staticClass:"fas fa-map-marker-alt"}),_vm._v(" Location: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"d-inline"},[_c('i',{staticClass:"fa fa-envelope"}),_vm._v(" Email: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"d-inline"},[_c('i',{staticClass:"fas fa-user-plus"}),_vm._v(" Social: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn btn-resume",attrs:{"target":"_blank"}},[_c('i',{staticClass:"far fa-file-pdf"}),_vm._v(" Resume ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text bg-white"},[_c('i',{staticClass:"fa fa-user"}),_vm._v("  ")])]),_c('input',{staticClass:"form-control border-left-0",attrs:{"name":"name","type":"name","placeholder":"Name","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('div',{staticClass:"input-group"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text bg-white"},[_c('i',{staticClass:"fa fa-envelope"})])]),_c('input',{staticClass:"form-control border-left-0",attrs:{"name":"email","type":"email","placeholder":"Email","required":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('textarea',{staticClass:"form-control",attrs:{"id":"message","rows":"5","placeholder":"Message","required":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"btn",attrs:{"type":"submit"}},[_c('i',{staticClass:"fa fa-paper-plane"}),_vm._v(" Send ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row arrow-container"},[_c('a',{staticClass:"arrow-icon",attrs:{"href":"#"}},[_c('i',{staticClass:"fas fa-chevron-up fa-4x"})])])
}]

export { render, staticRenderFns }